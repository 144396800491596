import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MConfirm = _resolveComponent("MConfirm")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Notification),
    (_ctx.showHeader)
      ? (_openBlock(), _createBlock(_component_TheHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isConfirmActive)
          ? (_openBlock(), _createBlock(_component_MConfirm, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_TheFooter, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}