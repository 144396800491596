import { ref } from 'vue'

const coords = ref({
    latitude: 0,
    longitude: 0,
})
export const useGeolocation = () => {
    const isNavigatorSupported = 'navigator' in window && 'geolocation' in navigator

    const fetchCoordinates = () => {
        if (isNavigatorSupported) {
            navigator.geolocation.getCurrentPosition(position => {
                coords.value.latitude = position.coords.latitude
                coords.value.longitude = position.coords.longitude
            })
        }
    }

    return {
        coords,
        fetchCoordinates,
    }
}
