
import { defineComponent, onMounted, ref } from 'vue'
import { useConfirm } from '@/composables/useConfirm'

export default defineComponent({
    setup() {
        const { closeConfirm, heading } = useConfirm()
        const confirmClass = ref('')

        onMounted(() => {
            setTimeout(() => {
                confirmClass.value = 'm-confirm__wrap--active'
            }, 150)
        })

        const close = (choice: boolean) => {
            confirmClass.value = ''
            setTimeout(() => {
                closeConfirm(choice)
            }, 300)
        }

        return {
            confirmClass,
            heading,
            close,
        }
    },
})
