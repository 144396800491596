import { ICarWash, ICarWashState, ICarWashStockItemToUpdate } from '@/interfaces/ICarWash'
import { reactive, toRefs } from 'vue'
import { useApi } from '@/composables/useApi'
import { useNotifications } from '@/composables/useNotifications'
import { IDemand } from '@/interfaces/IDemand'
import { IBarrelRefillPayload } from '@/interfaces/IBarrel'
import localforage from 'localforage'
import { ITempComment } from '@/interfaces/IDelivery'

const { usePost, useGet, usePatch } = useApi()
const { setNotification } = useNotifications()

const state: ICarWashState = reactive({
    activeCarWash: undefined,
    activeCarWashStock: {
        products: [],
        barrels: [],
    },
    carWashList: [],
    isLoading: false,
})

export const useCarWash = () => {
    const setActiveCarWash = async (carWash?: ICarWash, id?: number) => {
        if (carWash) {
            state.activeCarWash = carWash
        } else {
            state.activeCarWash = state.carWashList.find(elem => elem.id === id)
        }
    }

    const resetActiveCarWashStock = () => {
        state.activeCarWashStock = {
            products: [],
            barrels: [],
        }
    }

    const fetchCarWash = async (id: number | undefined) => {
        const url = `${process.env.VUE_APP_API}/v1/warehouses/car-wash-details/${id}`
        state.isLoading = true
        try {
            const result = await useGet(url)
            await setActiveCarWash(result.data.data, id)
            return result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchCarWashList = async () => {
        const url = `${process.env.VUE_APP_API}/v1/warehouse-list/car-wash-list`
        const options = {
            page: 1,
            limit: 25,
            sort: 'Warehouses.name',
            direction: 'asc',
        }
        state.isLoading = true
        try {
            const result = await usePost(url, options)
            state.carWashList = result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchCarWashStock = async (warehouseId?: number) => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status/warehouse`
        const options = {
            page: 1,
            limit: 25,
            sort: 'Products.name',
            direction: 'asc',
            warehouseId: warehouseId || state.activeCarWash?.id,
        }
        state.isLoading = true
        try {
            const result = await usePost(url, options)
            state.activeCarWashStock = result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const getBarrelDetails = (id: number) => {
        if (state.activeCarWashStock.barrels) {
            const result = state.activeCarWashStock.barrels.find(barrel => barrel.id === id)
            return result
        }
    }

    const fetchBarrelLevel = async (barrelId: number) => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status/barrel-level/${barrelId}`
        const options = {
            id: barrelId,
        }
        try {
            const result = await useGet(url, options)
            return result
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        }
    }

    const updateBarrelLevel = async (id: number, capacityUsageInPercents: number) => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status/barrel-level`
        const options = {
            id,
            capacityUsageInPercents,
        }
        try {
            const result = await usePatch(url, options)
            setNotification('Zaktualizowano poziom beczki')
            return result
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        }
    }

    const updateCarWashStock = async () => {
        const url = `${process.env.VUE_APP_API}/v1/stock-status/create`
        const tempComment: ITempComment | null = await localforage.getItem('tempComment')
        const productsPayload = state.activeCarWashStock.products.map(elem => {
            const product: ICarWashStockItemToUpdate = {
                id: elem.id,
                quantityWarehouse: elem.quantity,
            }
            return product
        })
        const options = {
            destinationWarehouseId: state.activeCarWash?.id,
            products: productsPayload,
            note: tempComment ? tempComment.comment : '',
        }
        state.isLoading = true
        try {
            await usePost(url, options)
            setNotification('Stany magazynowe zostały zaktualizowane')
            localforage.removeItem('tempComment')
            localforage.removeItem('activeCarWashStockProducts')
            localforage.removeItem('initalStock')
            localforage.removeItem('tempComment')
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        }
    }

    const createCarWashDemand = async (payload: IDemand) => {
        const url = `${process.env.VUE_APP_API}/v1/demands/create-demand`
        try {
            const result = await usePost(url, payload)
            setNotification('Wysłano zapotrzebowanie')
            return result
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchExpectedCarWashDemand = async (id: number) => {
        const url = `${process.env.VUE_APP_API}/v1/warehouses/car-wash-demand/${id}`
        state.isLoading = true
        try {
            const result = await useGet(url, id)
            return result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const refillBarrel = async (refillBarrelPayload: IBarrelRefillPayload) => {
        const url = `${process.env.VUE_APP_API}/v1/refill/create`
        const options = refillBarrelPayload
        try {
            const result = await usePost(url, options)
            setNotification('Zaktualizowano poziom beczki')
            return result
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        }
    }

    const storeActiveCarWashStock = async () => {
        const staticStock = JSON.parse(JSON.stringify(state.activeCarWashStock.products))
        await localforage.setItem('activeCarWashStockProducts', staticStock)
    }

    const getStoredActiveCarWashStock = async () => {
        const savedStock = await localforage.getItem('activeCarWashStockProducts')
        return savedStock
    }

    return {
        ...toRefs(state),
        fetchCarWash,
        fetchCarWashList,
        getBarrelDetails,
        fetchBarrelLevel,
        updateBarrelLevel,
        setActiveCarWash,
        fetchCarWashStock,
        updateCarWashStock,
        createCarWashDemand,
        fetchExpectedCarWashDemand,
        resetActiveCarWashStock,
        refillBarrel,
        storeActiveCarWashStock,
        getStoredActiveCarWashStock,
    }
}
