import { computed, reactive, toRefs } from 'vue'
import {
    IDelivery,
    IDeliveryState,
    IDeliveryNotes,
    IDeliveryProductQuantityTypes,
    IDeliveryProduct,
} from '@/interfaces/IDelivery'
import { useApi } from '@/composables/useApi'
import { useNotifications } from '@/composables/useNotifications'
import { useGeolocation } from '@/composables/useGeolocation'
import {
    EDeliveryActionHeadings as EHeadings,
    EDeliveryActions as EActions,
} from '@/enums/delivery'
import localforage from 'localforage'
import { ICarWashStockItem } from '@/interfaces/ICarWash'

const { usePost, useGet, usePatch } = useApi()
const { setNotification } = useNotifications()
const { coords } = useGeolocation()

const state: IDeliveryState = reactive({
    activeDeliveryStage: undefined,
    deliveryList: [],
    deliveryStages: [],
    currentDelivery: undefined,
    isLoading: false,
})

export const useDelivery = () => {
    const fetchDeliveryListManager = async () => {
        const url = `${process.env.VUE_APP_API}/v1/delivery/car-wash-manager`
        const options = {
            stageId: state.activeDeliveryStage?.id,
        }
        state.isLoading = true
        try {
            const result = await usePost(url, options)
            state.deliveryList = result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }
    const fetchDeliveryListCourier = async () => {
        const url = `${process.env.VUE_APP_API}/v1/delivery/delivery-man`
        state.isLoading = true

        let track
        if (state.activeDeliveryStage?.id === 3) track = true

        let coordinates
        if (coords.value.latitude || coords.value.longitude) coordinates = true

        const options = {
            track: coordinates && track,
            stageId: state.activeDeliveryStage?.id,
            latitude: coords.value.latitude,
            longitude: coords.value.longitude,
        }
        try {
            const result = await usePost(url, options)
            state.deliveryList = result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchDelivery = async (id: number) => {
        const url = `${process.env.VUE_APP_API}/v1/delivery/delivery-details/${id}`
        state.isLoading = true
        const options = {
            page: 1,
            limit: 25,
            sort: 'Product.name',
            direction: 'asc',
            latitude: coords.value.latitude,
            longitude: coords.value.longitude,
        }
        try {
            const result = await usePost(url, options)
            setCurrentDelivery(result.data.data)
            return result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const fetchDeliveryStages = async () => {
        if (!state.deliveryStages.length) {
            const url = `${process.env.VUE_APP_API}/v1/delivery/delivery-stages`
            try {
                const result = await useGet(url)
                state.deliveryStages = result.data.data.sort(
                    (a: any, b: any) => a.stageNumber - b.stageNumber,
                )
            } catch (error: any) {
                setNotification(error.response.data.message)
                throw Error(error.message)
            }
        }
    }

    const fetchSavedDeliveryReturns = async () => {
        const savedReturns: any = await localforage.getItem('deliveryReturns')
        if (savedReturns) {
            return savedReturns
        }
    }

    const sendDeliveryPhotos = async () => {
        const deliveryPhotos: any = await localforage.getItem('deliveryPhotos')
        if (deliveryPhotos) {
            const url = `${process.env.VUE_APP_API}/v1/photos/documents`
            const files = deliveryPhotos.photos.map((el: { url: string }) => el.url)
            const options = {
                objectTypeId: 1,
                objectId: deliveryPhotos.deliveryId,
                files,
            }
            try {
                await usePost(url, options)
                await localforage.removeItem('deliveryPhotos')
            } catch (error: any) {
                setNotification(error.response.data.message)
                throw Error(error.message)
            }
        }
    }

    const sendDeliveryReturns = async () => {
        const url = `${process.env.VUE_APP_API}/v1/delivery/return`
        const storedReturns = await fetchSavedDeliveryReturns()
        const payloadReturns = storedReturns.products.map((product: ICarWashStockItem) => {
            return {
                id: product.id,
                quantityWarehouse: product.quantity,
            }
        })
        const options = {
            carWashId: storedReturns.carWashId,
            products: payloadReturns,
        }
        state.isLoading = true
        try {
            await usePost(url, options)
            await removeSavedDeliveryReturns()
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const removeSavedDeliveryReturns = async () => {
        await localforage.removeItem('deliveryReturns')
    }

    const getDeliveryStageById = (id: number) => {
        if (id) {
            return state.deliveryStages.find(item => item.id === id)
        }
    }

    const getCurrentDeliveryStageType = computed(() => {
        let type
        if (state.currentDelivery) {
            const deliveryStageId = state.currentDelivery.deliveryStage[0].id
            if (deliveryStageId === 2) {
                type = 'Warehouse'
            } else if (deliveryStageId === 3) {
                type = 'Delivery'
            } else if (deliveryStageId === 4) {
                type = 'CarWash'
            }
        }
        return type
    })

    const getCurrentDeliveryNoteType = computed(() => {
        let type = 'Delivery'
        if (state.currentDelivery) {
            const deliveryStageId = state.currentDelivery.deliveryStage[0].id
            if (deliveryStageId === 4) {
                type = 'CarWash'
            }
        }
        return type
    })

    const getProductQuantity = (product: IDeliveryProduct) => {
        let qty = product.quantityWarehouse
        if (state.currentDelivery && state.currentDelivery.deliveryStage[0].stageNumber === 3) {
            qty = product.quantityDelivery
        }
        if (
            (state.currentDelivery && state.currentDelivery.deliveryStage[0].stageNumber === 4) ||
            (state.currentDelivery && state.currentDelivery.deliveryStage[0].stageNumber === 5)
        ) {
            qty = product.quantityCarWash
        }
        if (state.currentDelivery && state.currentDelivery.deliveryStage[0].stageNumber === 0) {
            if (product.quantityWarehouse !== product.quantityDelivery) {
                qty = product.quantityDelivery
            } else if (product.quantityDelivery !== product.quantityCarWash) {
                qty = product.quantityCarWash
            }
        }
        return qty
    }

    const setActiveDeliveryStage = (id: number) => {
        state.activeDeliveryStage = state.deliveryStages.find(stage => stage.id === id)
    }

    const setCurrentDelivery = (delivery: IDelivery | undefined) => {
        state.currentDelivery = delivery
    }

    const updateDeliveryStage = async (deliveryId: number) => {
        const url = `${process.env.VUE_APP_API}/v1/delivery`

        // get current note
        const noteType = 'note' + getCurrentDeliveryNoteType.value
        const notePayload = state.currentDelivery
            ? state.currentDelivery?.[noteType as keyof IDeliveryNotes]
            : ''

        // get current product quantities
        const currentQuantityType = 'quantity' + getCurrentDeliveryStageType.value
        let productsPayload = null
        if (state.currentDelivery && state.currentDelivery.products.length) {
            productsPayload = state.currentDelivery.products.map(el => {
                return {
                    productId: el.productId,
                    quantity: el[currentQuantityType as keyof IDeliveryProductQuantityTypes],
                }
            })
        }

        state.isLoading = true
        const options = {
            latitude: coords.value.latitude,
            longitude: coords.value.longitude,
            note: notePayload,
            deliveryId,
            products: productsPayload,
        }
        try {
            const result = await usePatch(url, options)
            await sendDeliveryPhotos()
            setCurrentDelivery(result.data.data)
            return result.data.data
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.message)
        } finally {
            state.isLoading = false
        }
    }

    const setDeliveryNote = (noteType: string | undefined, note: string) => {
        if (state.currentDelivery) {
            state.currentDelivery[('note' + noteType) as keyof IDeliveryNotes] = note
        }
    }

    const setDeliveryProduct = (productId: number, quantity: number) => {
        if (state.currentDelivery) {
            const findProduct = state.currentDelivery.products.find(
                el => el.productId === productId,
            )
            if (findProduct) {
                const quantityType = 'quantity' + getCurrentDeliveryStageType.value
                findProduct[quantityType as keyof IDeliveryProductQuantityTypes] = quantity
            }
        }
    }
    // Could be it's own composable, but leaving it here for now
    const storeDetailsId = async (paramsId: number) => {
        const storedDetailsId: number | null = await localforage.getItem('routeDetailsId')
        if (paramsId && paramsId !== storedDetailsId) {
            localforage.setItem('routeDetailsId', paramsId)
        }
    }

    const actions = [
        {
            heading: EHeadings.Details,
            action: EActions.Details,
        },
        {
            heading: EHeadings.Load,
            action: EActions.Load,
        },
        {
            heading: EHeadings.Comment,
            action: EActions.Comment,
        },
        {
            heading: EHeadings.Unload,
            action: EActions.Unload,
        },
    ]

    return {
        actions,
        fetchDeliveryListCourier,
        fetchDeliveryListManager,
        fetchDeliveryStages,
        fetchDelivery,
        getDeliveryStageById,
        getCurrentDeliveryNoteType,
        getProductQuantity,
        sendDeliveryReturns,
        sendDeliveryPhotos,
        setActiveDeliveryStage,
        setCurrentDelivery,
        setDeliveryProduct,
        setDeliveryNote,
        updateDeliveryStage,
        fetchSavedDeliveryReturns,
        removeSavedDeliveryReturns,
        storeDetailsId,
        ...toRefs(state),
    }
}
