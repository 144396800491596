<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="24"
            y="24"
            width="23"
            height="23"
            rx="11.5"
            transform="rotate(180 24 24)"
            stroke="#FFC600"
            stroke-width="2"
        />
        <path
            d="M13.4893 11.0018L13.4964 4.92773C13.4964 4.66142 13.3906 4.40603 13.2023 4.21773C13.014 4.02942 12.7586 3.92363 12.4923 3.92363C12.226 3.92363 11.9706 4.02942 11.7823 4.21773C11.594 4.40603 11.4882 4.66142 11.4882 4.92772L11.4953 11.0018L5.42121 10.9947C5.15491 10.9947 4.89951 11.1005 4.71121 11.2888C4.5229 11.4771 4.41712 11.7325 4.41712 11.9988C4.41712 12.2651 4.5229 12.5205 4.71121 12.7088C4.89951 12.8971 5.15491 13.0029 5.42121 13.0029L11.4953 12.9958L11.4882 19.0699C11.4876 19.2019 11.5132 19.3327 11.5635 19.4547C11.6138 19.5768 11.6877 19.6877 11.7811 19.7811C11.8744 19.8744 11.9853 19.9484 12.1074 19.9986C12.2295 20.0489 12.3603 20.0745 12.4923 20.074C12.6243 20.0745 12.7551 20.0489 12.8772 19.9986C12.9992 19.9484 13.1101 19.8744 13.2035 19.7811C13.2968 19.6877 13.3708 19.5768 13.421 19.4547C13.4713 19.3327 13.4969 19.2019 13.4964 19.0699L13.4893 12.9958L19.5633 13.0029C19.6954 13.0034 19.8262 12.9778 19.9482 12.9276C20.0703 12.8773 20.1812 12.8033 20.2745 12.71C20.3679 12.6167 20.4418 12.5057 20.4921 12.3837C20.5424 12.2616 20.568 12.1308 20.5674 11.9988C20.568 11.8668 20.5424 11.736 20.4921 11.6139C20.4418 11.4918 20.3679 11.3809 20.2745 11.2876C20.1812 11.1942 20.0703 11.1203 19.9482 11.07C19.8262 11.0198 19.6954 10.9942 19.5633 10.9947L13.4893 11.0018Z"
            fill="#FFC600"
        />
    </svg>
</template>
