import { reactive, toRefs } from 'vue'
import { IConfirmState } from '@/interfaces/IConfirm'

const state: IConfirmState = reactive({
    isActive: false,
    heading: undefined,
    acceptFunction: undefined,
    rejectFunction: undefined,
})

export const useConfirm = () => {
    const closeConfirm = (choice: boolean) => {
        if (choice === true && state.acceptFunction) {
            state.acceptFunction()
        } else if (choice === false && state.rejectFunction) {
            state.rejectFunction()
        }
        state.isActive = false
    }
    const initConfirm = (heading: string, accept: any, reject: any | undefined) => {
        state.isActive = true
        state.heading = heading
        state.acceptFunction = accept
        state.rejectFunction = reject
    }
    return {
        closeConfirm,
        initConfirm,
        ...toRefs(state),
    }
}
