import { reactive, toRefs, computed } from 'vue'
import { useApi } from '@/composables/useApi'
import { useNotifications } from '@/composables/useNotifications'
import { IToken } from '@/interfaces/IToken'
import { ERoles } from '@/enums/roles'

import jwtdecode from 'jwt-decode'
import localforage from 'localforage'
import router from '@/router'

const { useGet, usePost, readTokenFromStorage } = useApi()
const { setNotification } = useNotifications()
const state = reactive({
    token: '',
    isLoggedIn: false,
    loading: false,
    roleId: 0,
    role: '',
    firstLogin: false,
    guid: '',
})
export const useAuth = () => {
    const setToken = (token: string) => {
        state.token = token
    }

    const setGuid = async (guid: string) => {
        try {
            await localforage.setItem('guid', guid)
            state.guid = guid
        } catch (error: any) {
            throw Error(error)
        }
    }

    const checkTokenValidity = async () => {
        state.token = await readTokenFromStorage()
        if (
            (state.token === '' && state.isLoggedIn) ||
            (state.token === null && state.isLoggedIn)
        ) {
            logout()
        } else if (state.token === 'expired' && state.isLoggedIn) {
            logout('Upłynął zbyt długi czas od ostatniej aktywności. Nastąpiło wylogowanie.')
        } else if (state.token) {
            state.isLoggedIn = true
            return true
        }
    }

    const refreshToken = async () => {
        const url = `${process.env.VUE_APP_API}/v1/users/refresh-token`
        try {
            const result = await useGet(url)
            state.token = result.data.data.token
            await localforage.setItem('token', state.token)
            return result.data.data.token
        } catch (error: any) {
            logout()
            throw Error(error.response.data.message)
        }
    }

    const decodeToken = async () => {
        if (await readTokenFromStorage()) {
            const decodedToken: any = jwtdecode(await readTokenFromStorage())
            return decodedToken
        } else {
            return false
        }
    }

    const login = async (email: string, password: string) => {
        state.loading = true
        const url = `${process.env.VUE_APP_API}/v1/users/login`
        try {
            const result = await usePost(url, {
                email,
                passwordHash: password,
            })
            if (!result.data.data.token) {
                throw Error('No authorization token')
            }
            state.token = result.data.data.token
            await localforage.setItem('token', state.token)
            await assignRole()
            state.firstLogin = result.data.data.firstLogin
            state.isLoggedIn = true
        } catch (error: any) {
            setNotification(error.response.data.message)
            throw Error(error.response.data.message)
        } finally {
            state.loading = false
        }
        return state.token
    }
    const logout = async (message?: string) => {
        await localforage.removeItem('token')
        state.token = ''
        state.isLoggedIn = false
        setNotification(message || 'Wylogowano')
        if (!state.isLoggedIn) {
            router.push({ name: 'LoginForm' })
        }
    }

    const resetPassword = async (email: string) => {
        state.loading = true
        const url = `${process.env.VUE_APP_API}/v1/users/reset-password`
        try {
            const result = await usePost(url, {
                email,
            })
            setNotification(result.data.message)
            return result
        } catch (error: any) {
            setNotification(error.response.data.message)
        } finally {
            state.loading = false
        }
    }

    const setNewPassword = async (password: string, passwordRepeat: string, guid: string) => {
        state.loading = true
        const url = `${process.env.VUE_APP_API}/v1/users/new-password`
        try {
            const result = await usePost(url, {
                password,
                passwordRepeat,
                guid: guid || state.guid,
            })
            setNotification('Pomyślnie ustawiono hasło')
            state.guid = ''
            state.firstLogin = false
            localforage.removeItem('guid')
            return result
        } catch (error: any) {
            setNotification(error.response.data.message)
        } finally {
            state.loading = false
        }
    }

    const assignRole = async () => {
        const decodedToken: IToken = await decodeToken()
        if (!decodedToken && state.isLoggedIn) {
            logout()
        }
        const role = decodedToken.roles.find(role => role.id === 4 || role.id === 5)
        if (role) {
            state.role = role.id === 4 ? ERoles.Courier : ERoles.Manager
            state.roleId = role.id
        }
        return state.role
    }

    return {
        ...toRefs(state),
        setToken,
        setGuid,
        assignRole,
        login,
        logout,
        isLoggedIn: computed(() => !!state.token),
        checkTokenValidity,
        refreshToken,
        resetPassword,
        setNewPassword,
    }
}
