import { EApiMethods } from '@/enums/api'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import localforage from 'localforage'
import jwtdecode, { JwtPayload } from 'jwt-decode'

import router from '@/router'

const api = axios

const readTokenFromStorage = async () => {
    const storageToken = (await localforage.getItem('token')) as string
    // validate storageToken
    if (storageToken) {
        const decodedToken: JwtPayload = jwtdecode(storageToken)
        if (decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
            return storageToken
        } else {
            router.push({ name: 'LoginForm' })
            return 'expired'
        }
    }
    return ''
}

api.defaults.baseURL = process.env.VUE_APP_API
api.defaults.headers.common.Accept = 'application/json'
api.defaults.headers.common['Content-Type'] = 'application/json'
api.interceptors.response.use((response: AxiosResponse) => {
    if (response.data && response.headers['content-type'] === 'application/json') {
        response.data = camelizeKeys(response.data)
    }
    return response
})
api.interceptors.request.use(
    async config => {
        let token: string | null = ''
        try {
            token = await readTokenFromStorage()
            if (token) {
                config.headers.common.Authorization = `Bearer ${token}`
            }
        } catch (error) {
            Promise.reject(error)
        }
        const newConfig = { ...config }
        if (config.data) {
            newConfig.data = decamelizeKeys(config.data)
        }
        return newConfig
    },
    error => {
        return Promise.reject(error)
    },
)

export const useApi = (): {
    useGet: (url: string, params?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<any>>
    usePost: (url: string, params?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<any>>
    usePatch: (
        url: string,
        params?: any,
        config?: AxiosRequestConfig,
    ) => Promise<AxiosResponse<any>>
    readTokenFromStorage: () => Promise<string>
} => {
    const fetch = async (
        method: EApiMethods,
        url: string,
        params?: any,
        config?: AxiosRequestConfig,
    ) => {
        try {
            switch (method) {
                case EApiMethods.POST: {
                    return await api.post(url, params, config)
                }
                case EApiMethods.PATCH: {
                    return await api.patch(url, params)
                }
                case EApiMethods.GET: {
                    return await api.get(url, {
                        ...params,
                        ...config,
                    })
                }
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const useGet = async (url: string, params?: any, config?: AxiosRequestConfig) => {
        try {
            return fetch(EApiMethods.GET, url, params, config)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const usePost = async (url: string, params?: any, config?: AxiosRequestConfig) => {
        try {
            return fetch(EApiMethods.POST, url, params, config)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const usePatch = async (url: string, params?: any) => {
        try {
            return fetch(EApiMethods.PATCH, url, params)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        useGet,
        usePost,
        usePatch,
        readTokenFromStorage,
    }
}
