
import { defineComponent, onMounted, ref, watch } from 'vue'
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import MConfirm from '@/components/MConfirm.vue'
import Notification from '@/components/Notification.vue'

import { useRoute } from 'vue-router'
import { useConfirm } from '@/composables/useConfirm'
import { useDelivery } from '@/composables/useDelivery'
export default defineComponent({
    components: {
        TheFooter,
        TheHeader,
        Notification,
        MConfirm,
    },
    setup() {
        const route = useRoute()
        const { storeDetailsId } = useDelivery()
        const { isActive: isConfirmActive } = useConfirm()
        const showFooter = ref(true)
        const showHeader = ref(true)

        const hideNavigation = () => {
            if (route.meta.isFooterHidden) {
                showFooter.value = false
            } else {
                showFooter.value = true
            }

            if (route.meta.isHeaderHidden) {
                showHeader.value = false
            } else {
                showHeader.value = true
            }
        }

        onMounted(async () => {
            await storeDetailsId(+route.params.id)
        })

        watch(route, async () => {
            hideNavigation()
            await storeDetailsId(+route.params.id)
        })

        return {
            showHeader,
            showFooter,
            isConfirmActive,
        }
    },
})
