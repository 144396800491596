export enum EDeliveryActionHeadings {
    Details = 'Szczegóły dostawy',
    Load = 'Załadunek',
    Comment = 'Dodaj komentarz i zdjęcie',
    Unload = 'Rozładunek',
}

export enum EDeliveryActions {
    Details = 'delivery-details',
    Load = 'delivery-load',
    Comment = 'delivery-comment',
    Unload = 'delivery-unload',
    Closed = 'delivery-closed',
}

export enum EDeliveryStages {
    Created = 'created',
    Prepared = 'prepared',
    InDelivery = 'in_delivery',
    Delivered = 'delivered',
    Closed = 'closed',
    Explanation = 'explanation',
}
