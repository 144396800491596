import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "o-notifications__list" }
const _hoisted_2 = { class: "a-text o-notifications__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_ctx.status)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["o-notifications", { 'o-notifications--notLoggedIn': !_ctx.isLoggedIn }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "a-text o-notifications__item"
            }, [
              _createVNode(_component_a_icon, {
                class: "o-notifications__icon",
                icon: "Notification"
              }),
              _createElementVNode("p", _hoisted_2, _toDisplayString(message), 1)
            ]))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}