<template>
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.2166 23.9989H24.2376L24.2587 23.9998C24.3075 24.0018 24.356 23.9893 24.3979 23.9637C24.435 23.941 24.4656 23.909 24.4865 23.8707C24.5006 23.8294 24.5038 23.785 24.4955 23.7419C24.4862 23.6938 24.4632 23.6497 24.4294 23.6148C24.4289 23.6144 24.4285 23.6139 24.428 23.6134L21.9362 21.1182L21.2951 20.4762L21.8719 19.7759C23.5574 17.7293 24.4749 15.1564 24.4648 12.5032H24.4648V12.4994C24.4648 9.44916 23.2547 6.52409 21.1012 4.36763C20.0349 3.29987 18.7691 2.45295 17.3761 1.87516C15.9831 1.29737 14.4901 1 12.9824 1C9.93752 1 7.01707 2.21121 4.8636 4.36763C2.71008 6.52409 1.5 9.44916 1.5 12.4994C1.5 15.5497 2.71008 18.4748 4.8636 20.6313C7.01707 22.7877 9.93752 23.9989 12.9824 23.9989H24.2166Z"
            stroke="currentColor"
            stroke-width="2"
        />
        <rect x="5.5" y="11" width="15" height="2" rx="1" fill="currentColor" />
        <rect x="8.5" y="6" width="9" height="2" rx="1" fill="currentColor" />
        <rect x="8.5" y="16" width="9" height="2" rx="1" fill="currentColor" />
    </svg>
</template>
