
import { defineComponent } from 'vue'
import { useNotifications } from '@/composables/useNotifications'
import { useAuth } from '@/composables/useAuth'
export default defineComponent({
    setup() {
        const { messages, status } = useNotifications()
        const { isLoggedIn } = useAuth()
        return {
            messages,
            status,
            isLoggedIn,
        }
    },
})
