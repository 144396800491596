import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import { ERoles } from '@/enums/roles'
import localforage from 'localforage'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        redirect: { name: 'LoginForm' },
    },
    {
        path: '/manager/',
        component: () => import('@/views/manager/Manager.vue'),
        redirect: { name: 'CarWashList' },
        children: [
            {
                path: 'myjnie',
                component: () => import('@/views/manager/carWash/CarWash.vue'),
                meta: {
                    showCarWashDetails: true,
                },
                children: [
                    {
                        path: '',
                        name: 'CarWashList',
                        component: () => import('@/views/manager/carWash/CarWashList.vue'),
                        meta: {
                            isFooterHidden: false,
                            isHeaderHidden: false,
                            namePL: 'Lista myjni',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: ':id',
                        name: 'CarWashDetails',
                        component: () => import('@/views/manager/carWash/CarWashDetails.vue'),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: ':id/uzupelnij-zbiornik/:barrelId',
                        name: 'CarWashTankRefillManager',
                        component: () => import('@/views/manager/carWash/CarWashTankRefill.vue'),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: ':id/ustaw-zbiornik/:barrelId',
                        name: 'CarWashSetBarrelLevel',
                        component: () =>
                            import('@/views/manager/carWash/CarWashSetBarrelLevel.vue'),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: ':id/stany-magazynowe',
                        name: 'CarWashStock',

                        component: () => import('@/views/manager/carWash/CarWashStock.vue'),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: ':id/zapotrzebowanie',
                        name: 'CarWashDemand',
                        component: () => import('@/views/manager/carWash/CarWashDemand.vue'),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: ':id/dodaj-komentarz',
                        name: 'AddCommentManager',
                        component: () => import('@/views/AddComment.vue'),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                ],
            },
            {
                path: 'dostawy',
                name: 'DeliveryManager',
                component: () => import('@/views/manager/delivery/Delivery.vue'),
                redirect: { name: 'DeliveryList' },
                meta: {
                    deliveryDetails: true,
                },
                children: [
                    {
                        path: '',
                        name: 'DeliveryListManager',
                        component: () => import('@/views/manager/delivery/DeliveryList.vue'),
                        meta: {
                            namePL: 'Lista dostaw',
                            requiresAuth: true,
                        },
                        props: true,
                    },
                    {
                        path: ':id',
                        name: 'DeliveryDetailsManager',
                        component: () => import('@/views/manager/delivery/DeliveryDetails.vue'),
                        meta: {
                            showDeliveryDetails: true,
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                        props: true,
                    },
                    {
                        path: ':id/dodaj-komentarz',
                        name: 'DeliveryAddCommentManager',
                        component: () => import('@/views/AddComment.vue'),
                        props: true,
                        meta: {
                            showDeliveryDetails: true,
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/kurier/',
        component: () => import('@/views/courier/Courier.vue'),
        redirect: { name: 'DeliveryListCourier' },
        children: [
            {
                path: 'lista-dostaw',
                name: 'DeliveryListCourier',
                component: () => import('@/views/courier/DeliveryList.vue'),
                meta: {
                    namePL: 'Lista dostaw',
                    requiresAuth: true,
                },
            },
            {
                path: 'dostawa/:id',
                name: 'DeliveryCourier',
                component: () => import('@/views/courier/Delivery.vue'),
                meta: {
                    showDeliveryDetails: true,
                    requiresAuth: true,
                    goBackArrow: true,
                    deliveryDetails: true,
                },
                children: [
                    {
                        path: '',
                        name: 'DeliveryDetailsCourier',
                        component: () => import('@/views/courier/DeliveryDetails.vue'),
                        meta: {
                            showDeliveryDetails: true,
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: 'myjnia/:carWashId',
                        name: 'CarWashDetailsCourier',
                        component: () => import('@/views/manager/carWash/CarWashDetails.vue'),
                        props: true,
                        meta: {
                            showDeliveryDetails: true,
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: 'myjnia/:carWashId/uzupelnij-zbiornik/:barrelId',
                        name: 'CarWashTankRefillCourier',
                        component: () => import('@/views/manager/carWash/CarWashTankRefill.vue'),
                        props: true,
                        meta: {
                            showDeliveryDetails: true,
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: 'dodaj-komentarz',
                        name: 'AddCommentCourier',
                        component: () => import('@/views/AddComment.vue'),
                        props: true,
                        meta: {
                            showDeliveryDetails: true,
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                    {
                        path: 'zwroty',
                        name: 'DeliveryReturns',
                        component: () => import('@/views/courier/DeliveryReturns.vue'),
                        props: true,
                        meta: {
                            showDeliveryDetails: true,
                            requiresAuth: true,
                            goBackArrow: true,
                        },
                    },
                ],
            },
        ],
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        children: [
            {
                path: '',
                name: 'LoginForm',
                component: () => import('@/views/login/LoginForm.vue'),
                meta: { requiresAuth: false, isFooterHidden: true, isHeaderHidden: true },
            },
            {
                path: 'przypomnienie-hasla',
                name: 'ForgotPassword',
                component: () => import('@/views/login/ForgotPassword.vue'),
                meta: { requiresAuth: false, isFooterHidden: true, isHeaderHidden: true },
            },
            {
                path: 'nowe-haslo',
                /* Cant name this view as NewPassword as typescript
                    can't then load tsconfig and shows errors */
                name: 'NewPasswordForm',
                component: () => import('@/views/login/NewPasswordForm.vue'),
                meta: { requiresAuth: false, isFooterHidden: true, isHeaderHidden: true },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)',
        name: 'ErrorNotFound',
        component: () => import('@/views/ErrorNotFound.vue'),
        meta: { requiresAuth: false },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async to => {
    const { firstLogin, role, checkTokenValidity, isLoggedIn, assignRole, refreshToken } = useAuth()

    const storedDetailsId: string | null = await localforage.getItem('routeDetailsId')

    if (storedDetailsId && +storedDetailsId !== +to.params.id) {
        localforage.removeItem('tempComment')
        localforage.removeItem('deliveryReturns')
        localforage.removeItem('currentDeliveryProducts')
        localforage.removeItem('activeCarWashStockProducts')
        localforage.removeItem('currentDemand')
        localforage.removeItem('initalStock')
        localforage.removeItem('deliveryPhotos')
    }
    if (to.meta.requiresAuth && firstLogin.value) {
        return router.push({ name: 'NewPasswordForm' })
    }

    const routeName = `${String(to.name)}${role.value}`
    let findRouteName = false

    const searchForRoute = (routes: any[]) => {
        routes.forEach(route => {
            if (findRouteName) {
                return
            }
            if (route.name && route.name === routeName) {
                findRouteName = true
            }
            if (route.children && route.children.length) {
                searchForRoute(route.children)
            }
        })
    }
    searchForRoute(routes)
    if (to.name === 'LoginForm') {
        await checkTokenValidity()
    }
    // if route requires authorization
    if (to.meta.requiresAuth) {
        if (to.name !== 'Home') {
            await refreshToken()
        }
        await refreshToken()
        await checkTokenValidity()
        await assignRole()
    }
    // go to dashboard if logged in
    if ((to.name === 'LoginForm' || to.name === 'Home') && isLoggedIn.value) {
        await assignRole()
        if (role.value === ERoles.Manager) {
            router.push({ name: 'CarWashList' })
        }
        if (role.value === ERoles.Courier) {
            router.push({ name: 'DeliveryListCourier' })
        }
    }

    if (to.name !== routeName && findRouteName) {
        router.push({ name: routeName })
    }
})

export default router
