
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'AIcon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        iconClass: {
            type: String,
            default: undefined,
        },
        width: {
            type: String,
            default: undefined,
        },
        height: {
            type: String,
            default: undefined,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { attrs }) {
        let computedWidth
        if (props.width) {
            computedWidth = /[a-zA-Z]/i.test(props.width) ? props.width : props.width + 'px'
        }

        let computedHeight
        if (props.height) {
            computedHeight = /[a-zA-Z]/i.test(props.height) ? props.height : props.height + 'px'
        }

        if ('sm' in attrs) {
            computedWidth = '16px'
            computedHeight = '16px'
        }
        if ('lg' in attrs) {
            computedWidth = '32px'
            computedHeight = '32px'
        }

        return {
            computedWidth,
            computedHeight,
        }
    },
})
