<template>
    <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_526_2769)">
            <path
                d="M9.31747 17.3324C8.30562 17.3314 7.31399 17.049 6.45341 16.5168C4.23274 15.175 1.92734 16.2561 1.10425 16.7356C1.32899 17.6625 1.85804 18.4873 2.60682 19.0781C3.35559 19.6688 4.28083 19.9915 5.2346 19.9944H8.25136C9.24829 19.9913 10.2126 19.6389 10.9766 18.9984C11.7406 18.3579 12.2559 17.4699 12.4329 16.4888C11.7579 16.8669 10.6378 17.3324 9.31747 17.3324Z"
                fill="#FF3333"
            />
            <path
                d="M8.25139 -3.05176e-05H5.23463C4.10888 0.00340764 3.03023 0.452132 2.23421 1.24816C1.43819 2.04418 0.989461 3.12283 0.986023 4.24857V15.5372C2.22438 14.9236 4.64245 14.121 7.03165 15.5642C9.74208 17.202 12.4069 15.1983 12.432 15.1787C12.453 15.1627 12.4751 15.148 12.4981 15.135V4.24857C12.4947 3.12315 12.0462 2.04479 11.2506 1.24881C10.455 0.452844 9.37681 0.00389843 8.25139 -3.05176e-05ZM5.65828 3.26719C5.65828 2.62473 6.10054 2.24857 6.743 2.24857C7.40781 2.24857 7.82773 2.62101 7.82773 3.26719V8.26813C7.82773 8.91059 7.4078 9.28675 6.76535 9.28675C6.10054 9.28675 5.65828 8.91431 5.65828 8.26813V3.26719ZM8.04934 11.9581C8.05236 12.1301 8.02072 12.301 7.95628 12.4605C7.89185 12.62 7.79594 12.7649 7.67428 12.8866C7.55263 13.0082 7.40772 13.1041 7.2482 13.1685C7.08867 13.233 6.91781 13.2646 6.7458 13.2616C6.57378 13.2646 6.40292 13.233 6.2434 13.1685C6.08388 13.1041 5.93897 13.0082 5.81731 12.8866C5.69566 12.7649 5.59976 12.62 5.53532 12.4605C5.47089 12.301 5.43923 12.1301 5.44226 11.9581V11.945C5.43738 11.7725 5.46776 11.6008 5.53154 11.4405C5.59532 11.2801 5.69116 11.1345 5.81319 11.0124C5.93522 10.8904 6.08088 10.7945 6.24124 10.7308C6.4016 10.667 6.57329 10.6366 6.7458 10.6415C6.91831 10.6366 7.09 10.667 7.25037 10.7308C7.41073 10.7945 7.55638 10.8904 7.67841 11.0124C7.80044 11.1345 7.89629 11.2801 7.96007 11.4405C8.02385 11.6008 8.05422 11.7725 8.04934 11.945V11.9581Z"
                fill="#FF3333"
            />
        </g>
        <defs>
            <clipPath id="clip0_526_2769">
                <rect width="11.514" height="20" fill="white" transform="translate(0.986023)" />
            </clipPath>
        </defs>
    </svg>
</template>
