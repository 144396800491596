import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "o-footer" }
const _hoisted_2 = { class: "o-footer__notificationAmount o-footer__notificationAmount--primary" }
const _hoisted_3 = { class: "o-footer__notificationAmount o-footer__notificationAmount--primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", {
      class: "o-footer__item o-footer__notifications",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick('DeliveryList' + _ctx.role)))
    }, [
      _createElementVNode("em", _hoisted_2, _toDisplayString(_ctx.deliveryListLength), 1),
      _createVNode(_component_a_icon, {
        class: "o-footer__notification",
        icon: "Truck",
        clickable: true
      })
    ]),
    (_ctx.role === 'Manager')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "o-footer__item o-footer__notifications",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick('CarWashList')))
        }, [
          _createElementVNode("em", _hoisted_3, _toDisplayString(_ctx.carWashListLength), 1),
          _createVNode(_component_a_icon, {
            class: "o-footer__notification",
            icon: "CarWash",
            clickable: true
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}