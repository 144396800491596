<template>
    <svg width="15" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 1L9 9L17 1"
            stroke="#000"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
