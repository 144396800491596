<template>
    <svg width="36" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.20001 16.1333V18.4308C1.20001 18.8116 1.34751 19.1768 1.61007 19.4461C1.87262 19.7154 2.22871 19.8667 2.60001 19.8667H6.80001"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M13.9845 19.8667H21.2196C21.6034 19.8667 21.9715 19.7177 22.2428 19.4525C22.5142 19.1873 22.6667 18.8276 22.6667 18.4525V2.61414C22.6667 2.23908 22.5142 1.8794 22.2428 1.61419C21.9715 1.34899 21.6034 1.2 21.2196 1.2H4"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M4.00001 1.2H2.4174C2.09453 1.2 1.78489 1.35197 1.55658 1.62248C1.32828 1.89298 1.20001 2.25987 1.20001 2.64242V17.0667"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M10.0667 23.6C11.8708 23.6 13.3334 22.1375 13.3334 20.3333C13.3334 18.5292 11.8708 17.0667 10.0667 17.0667C8.26255 17.0667 6.80002 18.5292 6.80002 20.3333C6.80002 22.1375 8.26255 23.6 10.0667 23.6Z"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M28.7333 23.6C30.5375 23.6 32 22.1375 32 20.3333C32 18.5292 30.5375 17.0667 28.7333 17.0667C26.9292 17.0667 25.4667 18.5292 25.4667 20.3333C25.4667 22.1375 26.9292 23.6 28.7333 23.6Z"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M18.9333 19.8667H25.4667"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M32.0424 19.8667H33.4212C33.7869 19.8667 34.1376 19.7191 34.3962 19.4565C34.6548 19.1939 34.8 18.8378 34.8 18.4664V13.1299C34.8001 12.7794 34.6707 12.4417 34.4374 12.1833L29.1428 6.32036C29.0137 6.17735 28.8566 6.06318 28.6817 5.98507C28.5068 5.90697 28.3178 5.86665 28.1267 5.86667H22.6667"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
        <path
            d="M34.8 12.4H27.8C27.4287 12.4 27.0726 12.2634 26.8101 12.0203C26.5475 11.7772 26.4 11.4475 26.4 11.1037V7.73332"
            stroke="#0F172A"
            stroke-width="2"
            stroke-miterlimit="10"
        />
    </svg>
</template>
