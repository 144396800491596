import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-confirm" }
const _hoisted_2 = { class: "m-confirm__heading a-text a-text--semibold a-text--big a-text--white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["m-confirm__wrap", _ctx.confirmClass])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.heading), 1),
      _createElementVNode("button", {
        class: "a-button a-button--primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close(true)))
      }, "Tak"),
      _createElementVNode("button", {
        class: "a-button a-button--secondary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close(false)))
      }, "Nie")
    ], 2)
  ]))
}