import { reactive, toRefs } from 'vue'
import { INotification } from '@/interfaces/INotification'

const state: INotification = reactive({
    status: false,
    messages: [],
    statusTimeout: 4000,
})

export const useNotifications = () => {
    const setStatus = (status: boolean) => {
        state.status = status
    }
    const setMessage = (message: string) => {
        if (!state.messages.find(item => item === message)) {
            state.messages.push(message)
        }
    }
    const removeMessages = () => {
        state.messages = []
    }

    const setNotification = (message: string) => {
        setStatus(true)
        setMessage(message)
        setTimeout(() => {
            setStatus(false)
            removeMessages()
        }, state.statusTimeout)
    }

    return {
        ...toRefs(state),
        setNotification,
    }
}
