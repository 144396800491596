
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import { ERoles } from '@/enums/roles'
import { useDelivery } from '@/composables/useDelivery'
import { useCarWash } from '@/composables/useCarWash'

export default defineComponent({
    setup() {
        const { fetchCarWashList, carWashList } = useCarWash()
        const { role, assignRole } = useAuth()
        const router = useRouter()
        const route = useRoute()
        const { fetchDeliveryListCourier, fetchDeliveryListManager, deliveryList } = useDelivery()
        const carWashListLength = computed(() => carWashList.value.length)
        const deliveryListLength = computed(
            // Filter out Closed (id 5) deliveries
            () => deliveryList.value.filter(item => item.deliveryStage.stageNumber !== 5).length,
        )
        const fetchData = async () => {
            const userRole = await assignRole()
            if (userRole === ERoles.Courier) {
                fetchDeliveryListCourier()
            } else if (userRole === ERoles.Manager) {
                fetchDeliveryListManager()
            }
            fetchCarWashList()
        }

        fetchData()

        const handleClick = (module: string) => {
            if (route.name === module) {
                router.go(0)
            } else {
                router.push({
                    name: module,
                })
            }
        }

        return {
            role,
            ERoles,
            deliveryList,
            handleClick,
            carWashListLength,
            deliveryListLength,
        }
    },
})
