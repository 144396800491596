<template>
    <svg width="33" height="31" viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.356 16.636C18.7166 16.5876 20.0786 16.668 21.424 16.876C21.6803 16.9349 21.9205 17.0493 22.1278 17.211C22.3351 17.3727 22.5045 17.5778 22.624 17.812L23.824 20.212"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.912 20.2C13.0507 20.6059 15.2292 20.7629 17.404 20.668C19.5828 20.7622 21.7652 20.6052 23.908 20.2"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.356 16.636C15.9995 16.5877 14.6414 16.6681 13.3 16.876C13.0431 16.9334 12.8022 17.0471 12.5947 17.2089C12.3872 17.3708 12.2182 17.5768 12.1 17.812L10.9 20.212"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M23.86 20.2L24.904 21.4C25.1779 21.702 25.3279 22.0963 25.324 22.504V23.968C25.3245 24.3903 25.166 24.7973 24.88 25.108V25.108H24.952V28.264C24.952 28.3586 24.9334 28.4522 24.8972 28.5396C24.861 28.6269 24.808 28.7063 24.7411 28.7731C24.6742 28.84 24.5949 28.893 24.5075 28.9292C24.4202 28.9654 24.3265 28.984 24.232 28.984H22.276C22.1814 28.984 22.0878 28.9654 22.0005 28.9292C21.9131 28.893 21.8337 28.84 21.7669 28.7731C21.7 28.7063 21.647 28.6269 21.6108 28.5396C21.5746 28.4522 21.556 28.3586 21.556 28.264V26.596L21.904 26.44H12.856L13.216 26.596V28.264C13.216 28.3586 13.1974 28.4522 13.1612 28.5396C13.125 28.6269 13.072 28.7063 13.0051 28.7731C12.9382 28.84 12.8589 28.893 12.7715 28.9292C12.6842 28.9654 12.5905 28.984 12.496 28.984H10.54C10.4454 28.984 10.3518 28.9654 10.2645 28.9292C10.1771 28.893 10.0977 28.84 10.0309 28.7731C9.96403 28.7063 9.91099 28.6269 9.8748 28.5396C9.83862 28.4522 9.81998 28.3586 9.81998 28.264V25.144H9.89198C9.6026 24.8345 9.43987 24.4277 9.43597 24.004V22.54C9.4365 22.1309 9.5907 21.7368 9.86799 21.436L10.912 20.236"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M24.076 20.44L25.156 19.876"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.66 20.44L9.56802 19.876"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.24 5.03203C17.038 3.84572 17.895 2.70025 18.808 1.60001C18.808 1.60001 20.644 3.84403 21.376 5.03203C21.7916 5.58194 22.0839 6.2149 22.233 6.88787C22.3821 7.56085 22.3845 8.25804 22.24 8.93203C22.0209 9.67364 21.5688 10.325 20.9507 10.7897C20.3326 11.2543 19.5813 11.5076 18.808 11.512C18.0347 11.5076 17.2834 11.2543 16.6653 10.7897C16.0472 10.325 15.5951 9.67364 15.376 8.93203C15.2267 8.2583 15.2266 7.56004 15.3759 6.8863C15.5252 6.21255 15.8201 5.57965 16.24 5.03203Z"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M7.15599 4.02399C7.63599 3.24399 8.84799 1.78 8.84799 1.78C9.44707 2.49793 10.0078 3.24696 10.528 4.02399C10.8032 4.3852 10.9963 4.80216 11.0937 5.24571C11.1911 5.68926 11.1905 6.14871 11.092 6.59201C10.9433 7.07411 10.6464 7.49709 10.2435 7.80084C9.84071 8.10458 9.3524 8.27364 8.84799 8.28401C8.34151 8.27614 7.85053 8.10824 7.44529 7.80431C7.04006 7.50039 6.74135 7.07603 6.59199 6.59201C6.49344 6.14871 6.49285 5.68926 6.59026 5.24571C6.68768 4.80216 6.88076 4.3852 7.15599 4.02399Z"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M30.448 4.02399C29.9279 3.24696 29.3671 2.49793 28.768 1.78C28.768 1.78 27.568 3.24399 27.076 4.02399C26.8008 4.3852 26.6077 4.80216 26.5103 5.24571C26.4129 5.68926 26.4135 6.14871 26.512 6.59201C26.6614 7.07603 26.9601 7.50039 27.3653 7.80431C27.7705 8.10824 28.2616 8.27614 28.768 8.28401C29.2725 8.27364 29.7608 8.10458 30.1636 7.80084C30.5664 7.49709 30.8633 7.07411 31.012 6.59201C31.1106 6.14871 31.1112 5.68926 31.0138 5.24571C30.9163 4.80216 30.7233 4.3852 30.448 4.02399V4.02399Z"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1.60001 29.272H5.26001"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M1.60001 29.272V4.78H2.87201"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M31.312 12.376H25.54"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M31.312 17.896H29.704"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M31.312 23.464H29.608"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M31.312 28.936H29.608"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9.80797 12.376H5.24799V29.272"
            stroke="#0F172A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
