<template>
    <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.4375 18C8.4375 19.0701 7.57005 19.9375 6.5 19.9375C5.42995 19.9375 4.5625 19.0701 4.5625 18C4.5625 16.9299 5.42995 16.0625 6.5 16.0625C7.57005 16.0625 8.4375 16.9299 8.4375 18Z"
            fill="#FFC600"
            stroke="#FFC600"
            stroke-width="0.125"
        />
        <path
            d="M6.5 13.25V11.8437C7.4889 11.8437 8.4556 11.5551 9.27785 11.0143C10.1001 10.4734 10.741 9.70475 11.1194 8.80539C11.4978 7.90604 11.5968 6.91642 11.4039 5.96167C11.211 5.00692 10.7348 4.12992 10.0355 3.44159C9.33627 2.75325 8.44536 2.28449 7.47545 2.09457C6.50555 1.90466 5.50021 2.00213 4.58658 2.37466C3.67295 2.74718 2.89206 3.37803 2.34265 4.18743C1.79325 4.99683 1.5 5.94842 1.5 6.92188"
            stroke="#FFC600"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
